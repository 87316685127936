<template>
  <div>
    <v-row>
      <v-col>
        <div class="text-caption mb-2">
          {{ actorsTitle }}
          <v-chip v-if="actors.length > 1" small>
            {{ actors.length }}
          </v-chip>
        </div>
        <div class="mb-2">
          <form-deposit-add-teammate
            v-if="rights === 'author' && profile.accountType === 'business'"
            :authors="actorsFiltered"
            :disabled="disabled"
            @add="addTeammate"
          />
          <v-btn
            v-else
            :disabled="disabled"
            :ripple="false"
            color="primary"
            outlined
            rounded
            small
            @click="addActor('individual')"
          >
            {{ addActorButtonTitle }}
          </v-btn>
        </div>
      </v-col>
      <v-col v-if="actors.length > 1" cols="auto">
        <form-contribution-weight-mode
          :value="contributionWeightMode"
          @change="changeContributionWeightMode"
        />
      </v-col>
    </v-row>

    <transition-group name="actors" tag="div" class="actors-transition">
      <template v-for="(actor, i) in actors">
        <form-deposit-actor-individual
          v-if="actor"
          :key="`actor-${i}`"
          :actor="actor"
          :cw-mode="contributionWeightMode"
          :disabled="disabled"
          :server-feedback="feedback(i)"
          class="mb-5"
          @input="input(i, $event)"
          @remove="removeActor(i)"
        />
      </template>
    </transition-group>

    <transition name="fade">
      <progress-bar-contribution
        v-if="actorsFiltered.length > 1"
        :actors="actorsFiltered"
        :cw-mode="contributionWeightMode"
        class="mb-3 fractions-progress-bar"
      />
    </transition>

    <v-row>
      <v-col>
        <p v-show="allActorsFeedback" class="error--text text-caption">
          {{ allActorsFeedback }}
        </p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import {cloneDeep} from 'lodash'
import {convertDecimalToFraction} from '@/util/util'
import ProgressBarContribution from '@/components/ProgressBarContribution'
import FormContributionWeightMode from '@/components/FormContributionWeightMode'
import FormDepositActorIndividual from '@/components/FormDepositActorIndividual'
import FormDepositAddTeammate from '@/components/FormDepositAddTeammate'

const today = () => {
  let now = new Date()
  let y = now.getFullYear()
  let m = (now.getMonth() + 1).toString().padStart(2, '0')
  let d = now.getDate().toString().padStart(2, '0')
  return `${y}-${m}-${d}`
}

const newActor = {
  name: null,
  rights: null,
  status: null,
  contributionWeight: null,
  territory: ['00'],
  dateFrom: today()
}

export default {
  name: 'FormDepositActors',
  components: {
    FormContributionWeightMode,
    FormDepositAddTeammate,
    FormDepositActorIndividual,
    ProgressBarContribution
  },
  props: {
    actors: {type: Array, required: true},
    disabled: {type: Boolean, default: false},
    rights: {type: String, required: true},
    serverFeedback: {type: Object, required: true}
  },
  data () {
    return {
      contributionWeightMode: '',
      allActorsFeedback: ''
    }
  },
  computed: {
    ...mapState({
      profile: state => state.profile,
      defaultAuthorName: state => state.profile.name
    }),
    allowChangeActors () {
      return !this.profile.accountType
    },
    actorsFiltered () {
      return this.actors.filter(a => a !== null)
    },
    actorsTitle () {
      let title = this.$t('Actor', {count: this.actors.length})
      if (this.rights === 'author') {
        title = this.$t('Author', {count: this.actors.length})
      }
      if (this.rights === 'rightsholder') {
        title = this.$t('Rightsholder', {count: this.actors.length})
      }
      return title
    },
    addActorButtonTitle () {
      let title = this.$t('Add actor')
      if (this.rights === 'author') {
        title = this.$t('Add author')
      }
      if (this.rights === 'rightsholder') {
        title = this.$t('Add rightsholder')
      }
      return title
    },
  },
  watch: {
    serverFeedback (newVal) {
      this.allActorsFeedback = this.message(newVal.actors)
    },
  },
  mounted () {
    let cwString = this.actorsFiltered?.[0]?.contributionWeight
    this.contributionWeightMode = /^\d+\/\d+$/.test(cwString)
      ? 'fraction'
      : 'percent'
  },
  methods: {
    message (value) {
      if (Array.isArray(value)) return value.join(' ')
      return value || ''
    },
    feedback (actorIndex) {
      let feedback = {}
      Object.keys(this.serverFeedback)
        .filter(key => key.startsWith(`actors.${actorIndex}.`))
        .forEach(key => {
          feedback[key.split('.')[2]] = this.serverFeedback[key]
        })
      return feedback
    },
    changeContributionWeightMode (mode) {
      this.contributionWeightMode = mode
      this.actorsFiltered.forEach(actor => {
        if (mode === 'percent') {
          if (/^\d+\/\d+$/.test(actor.contributionWeight)) {
            let [num, den] = actor.contributionWeight.split('/')
            actor.contributionWeight = (num / den).toString().substr(0, 4)
          } else {
            actor.contributionWeight = ''
          }
        } else {
          actor.contributionWeight =
            convertDecimalToFraction(actor.contributionWeight)
        }
      })
      this.$emit('input', cloneDeep(this.actors))
    },
    input (index, actor) {
      this.allActorsFeedback = ''
      let actors = cloneDeep(this.actors)
      actors[index] = actor
      this.$emit('input', actors)
    },
    addActor (personType) {
      this.allActorsFeedback = ''
      let actors = cloneDeep(this.actors)
      let actor = cloneDeep(newActor)
      actor.rights = this.rights
      actor.status = personType

      if (!actors.length) {
        actor.name = this.defaultAuthorName
      }

      actors.push(actor)
      this.$emit('input', this.recalcContribution(actors))
    },
    addTeammate (author) {
      this.allActorsFeedback = ''
      let actors = cloneDeep(this.actors)
      actors.push(author)
      this.$emit('input', this.recalcContribution(actors))
    },
    removeActor (index) {
      this.allActorsFeedback = ''
      let actors = cloneDeep(this.actors)
      let [{rights}] = actors.splice(index, 1)
      this.$emit('input', this.recalcContribution(actors, rights))
    },
    recalcContribution (actors, rights = 'author') {
      let cwMode = ''
      actors = actors.filter(a => a && a.rights === rights)
      if (actors.length > 1) {
        cwMode = (100 / actors.length) % 1
          ? 'fraction'
          : 'percent'

        actors.forEach((actor, i) => {
          if (actor && actor.rights === rights) {
            actors[i].contributionWeight = cwMode === 'percent'
              ? (1 / actors.length).toString()
              : `1/${actors.length}`
          }
        })
      } else if (actors.length === 1) {
        let i = actors.findIndex(a => a && a.rights === rights)
        if (i !== -1) actors[i].contributionWeight = '1.0'
      }
      this.contributionWeightMode = cwMode
      return actors
    }
  }
}
</script>
