<template>
  <div>
    <v-row>
      <v-col>
        <div class="algo-progress">
          <div class="algo-progress-filler grey lighten-3" />
          <div
            v-for="(actor, i) in actors"
            :key="`fraction-${i}`"
            :style="{width: `calc(${actor.contributionWeight || 0} * 100%)`}"
            class="algo-progress-bar"
          >
            {{ fractionLabel(actor.contributionWeight) }}
            <div :class="progressVariants[i]" />
          </div>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col v-for="(actor, i) in actors" :key="`legend-${i}`" cols="auto">
        <v-icon :class="`mr-1 ${progressVariants[i]}--text`" x-small>
          {{ icons.square }}
        </v-icon>
        <span v-if="actor.name">{{ actor.name }}</span>
        <span v-else class="grey--text">
          {{ $t('New author') }}
        </span>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {mdiSquare} from '@mdi/js'

export default {
  name: 'ProgressBarContribution',
  props: {
    actors: {type: Array, required: true},
    cwMode: {type: String, required: true}
  },
  data () {
    return {
      progressVariants: [
        'red',
        'light-blue',
        'yellow',
        'pink',
        'cyan',
        'amber',
        'purple',
        'teal',
        'orange',
        'deep-purple',
        'green',
        'deep-orange',
        'indigo',
        'light-green',
        'brown',
        'blue',
        'lime',
        'blue-grey'
      ],
      icons: {
        square: mdiSquare
      }
    }
  },
  methods: {
    fractionLabel (cw) {
      let label = ''
      if (this.cwMode === 'percent' && cw > 0) {
        label = Math.trunc(cw * 100).toString() + '%'
      }
      if (this.cwMode === 'fraction' && /^\d+\/\d+$/.test(cw)) {
        label = cw
      }
      return label
    }
  }
}
</script>

<style lang="scss" scoped>
  .algo-progress {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
    width: 100%;

    .algo-progress-bar {
      text-align: center;
      align-self: flex-end;
      min-width: 15px !important;
      z-index: 2;
      transition: width .3s ease-out;

      & > div {
        height: 5px;
      }
    }

    .algo-progress-filler {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 5px;
      z-index: 1;
    }
  }
</style>
