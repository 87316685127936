<template>
  <v-card
    v-bind="$attrs"
    :max-width="previewSize"
    :ripple="false"
    class="pa-1 mx-auto text-decoration-none"
    height="100%"
  >
    <v-img :height="previewSize" :src="src" :width="previewSize" />
    <v-card-text class="pt-2">
      <div class="d-flex justify-space-between align-center">
        <div>
          {{ humanize(size) }},
          {{ dimensions }}
        </div>
        <v-btn v-if="removable" color="red" fab small text>
          <v-icon @click="$emit('remove')" v-text="icons.remove" />
        </v-btn>
      </div>
      <div class="text-caption text-break">
        {{ filename }}
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import {mapState} from 'vuex'
import {mdiClose} from '@mdi/js'
import humanizeSizeMixin from '@/mixins/humanize-size'

export default {
  name: 'ImagePreview',
  mixins: [humanizeSizeMixin],
  props: {
    dimensions: {type: String, default: ''},
    filename: {type: String, default: ''},
    removable: {type: Boolean, default: false},
    size: {type: Number, required: true},
    src: {type: String, required: true}
  },
  data () {
    return {
      icons: {
        remove: mdiClose
      }
    }
  },
  computed: {
    ...mapState({
      previewSize: state => state.config.deposit?.types?.screens?.previewSize
    })
  }
}
</script>
