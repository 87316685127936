export default {
  methods: {
    humanize (size) {
      if (size < 1000) {
        return '<1 Kb'
      }
      if (size < 1024 ** 2) {
        return Math.round(size / 1024) + ' Kb'
      }
      if (size < 1024 ** 3) {
        return Math.round(size * 10 / 1024 ** 2) / 10 + ' Mb'
      }
      return Math.round(size * 10 / 1024 ** 3) / 10 + ' Gb'
    }
  }
}
