<template>
  <v-card flat outlined>
    <v-card-text class="pb-0">
      <v-row>
        <!-- author name -->
        <v-col>
          <v-text-field
            :disabled="disabled || disallowChangeActors"
            :error-messages="feedback('name')"
            :label="nameFieldLabel"
            :rules="rules.name"
            :value="actor.name"
            outlined
            @input="input('name', $event)"
          />
        </v-col>
        <!-- date from -->
        <v-col sm="3" cols>
          <v-menu
            v-model="datepickerOpen"
            :close-on-content-click="false"
            min-width="290px"
            offset-y
            transition="scale-transition"
          >
            <template v-slot:activator="{on}">
              <v-text-field
                :error-messages="feedback('dateFrom')"
                :label="$t('Date from')"
                :value="actor.dateFrom"
                outlined
                readonly
                v-on="on"
              />
            </template>
            <v-date-picker
              :disabled="disabled"
              :min="(new Date()).toISOString()"
              :value="actor.dateFrom"
              color="primary"
              locale="en"
              show-current
              @input="input('dateFrom', $event)"
            />
          </v-menu>
        </v-col>
        <!-- contribution weight -->
        <v-col v-if="cwMode" cols="3">
          <form-contribution-weight
            :disabled="disabled"
            :feedback="feedback('contributionWeight')"
            :mode="cwMode"
            :value="actor.contributionWeight"
            @change="input('contributionWeight', $event)"
          />
        </v-col>
      </v-row>

      <v-row>
        <!-- territories -->
        <v-col>
          <v-combobox
            v-model="territory"
            :disabled="disabled"
            :error-messages="feedback('territory')"
            :items="territoriesOptions"
            :label="$t('Territories')"
            :ripple="false"
            hide-details="auto"
            chips
            multiple
            outlined
            small-chips
            @change="input('territory', $event)"
          />
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions class="px-4 pb-4 justify-space-between">
      <div class="body-2 error--text">
        {{ extraFeedback }}
      </div>
      <v-btn
        :ripple="false"
        class="px-5"
        color="error"
        outlined
        rounded
        text
        @click="remove"
      >
        {{ $t('Remove') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {mapGetters, mapState} from 'vuex'
import {mdiClose} from '@mdi/js'
import {cloneDeep} from 'lodash'
import FormContributionWeight from '@/components/FormContributionWeight'

export default {
  name: 'FormDepositIndividual',
  components: {FormContributionWeight},
  props: {
    actor: {type: Object, required: true},
    cwMode: {type: String, required: true},
    disabled: {type: Boolean, default: false},
    serverFeedback: {type: Object, required: true}
  },
  data () {
    return {
      datepickerOpen: false,
      territory: [],
      icons: {
        remove: mdiClose
      },
      rules: {
        name: [v => !!v || this.$t('Name is required')],
      }
    }
  },
  computed: {
    ...mapState({
      profile: state => state.profile,
    }),
    ...mapGetters({
      territoriesOptions: 'getTerritoriesOptions'
    }),
    disallowChangeActors () {
      return this.profile.accountType === 'business'
    },
    nameFieldLabel () {
      return this.actor.rights === 'author'
        ? this.$t('Author name')
        : this.$t('Rightsholder name')
    },
    extraFeedback () {
      return Object.keys(this.serverFeedback)
        .filter(field => {
          return ![
            'contributionWeight',
            'dateFrom',
            'name',
            'territory',
          ].includes(field)
        })
        .map(field => this.feedback(field))
        .join(' ')
    },
  },
  mounted () {
    this.territory = this.territoriesOptions.filter(t => {
      return this.actor.territory.includes(t.value)
    })
  },
  methods: {
    feedback (field) {
      if (Array.isArray(this.serverFeedback[field])) {
        return this.serverFeedback[field].join(' ')
      }
      return this.serverFeedback[field] || ''
    },
    input (field, value) {
      if (field === 'dateFrom') {
        this.datepickerOpen = false
      }
      if (field === 'territory') {
        value = value.map(t => t.value)
      }
      if (field === 'contributionWeight') {
        if (this.cwMode === 'percent') {
          value = value === 100 ? '1.0' : (value / 100).toString()
        } else {
          value = value?.replace(/\s+/g, '')
        }
      }

      let actor = cloneDeep(this.actor)
      actor[field] = value
      this.$emit('input', actor)
    },
    remove () {
      this.$emit('remove')
    }
  }
}
</script>
