<template>
  <div class="screens-container mt-3">
    <div
      :class="{dragover, 'dropzone-error': localError}"
      class="dropzone"
      @click="openChooseFile"
      @dragenter.prevent="dragover = true"
      @dragleave.prevent="dragover = false"
      @dragover.prevent="dragover = true"
      @drop.prevent.stop="onDrop"
    >
      <input
        ref="fileInput"
        multiple
        type="file"
        @change="onDrop"
      >
    </div>
    <v-row
      @dragenter.prevent="dragover = true"
      @dragleave.prevent="dragover = false"
      @dragover.prevent="dragover = true"
      @drop.prevent.stop="onDrop"
    >
      <v-col
        v-for="(image, i) in undeletedFiles"
        :key="`file-${i}`"
        cols="12"
        lg="6"
        md="4"
        sm="6"
        xl="4"
      >
        <image-preview
          :dimensions="`${image.width}×${image.height}`"
          :filename="image.filename"
          :size="Number(image.size)"
          :src="image.preview[previewSize]"
          removable
          @remove="removeFile(i)"
        />
      </v-col>
      <v-col
        v-for="(image, i) in contentImages"
        :key="`content-${i}`"
        cols="12"
        lg="6"
        md="4"
        sm="6"
        xl="4"
      >
        <image-preview
          :dimensions="`${image.width}×${image.height}`"
          :filename="image.filename"
          :size="Number(image.size)"
          :src="image.srcBase64"
          removable
          @remove="removeImage(i)"
        />
      </v-col>
    </v-row>

    <div
      :class="{'pushed-to-bottom': contentImages || fileImages.length}"
      class="dropzone-label text-center"
      @click="openChooseFile"
      @dragenter.prevent="dragover = true"
      @dragleave.prevent="dragover = false"
      @dragover.prevent="dragover = true"
      @drop.prevent.stop="onDrop"
    >
      <div v-if="localError" class="text-center error--text">
        {{ localError }}
      </div>
      <h2 v-else>
        {{ $t('Drop screenshots here to upload or click to choose files') }}
      </h2>
    </div>
  </div>
</template>

<script>
import {mapMutations, mapState} from 'vuex'
import ImagePreview from '@/components/ImagePreview'
import humanizeSizeMixin from '@/mixins/humanize-size'

export default {
  name: 'FormDepositScreens',
  components: {ImagePreview},
  mixins: [humanizeSizeMixin],
  props: {
    content: {type: Array, required: true},
    errorMessages: {type: String, default: ''},
    files: {type: Array, required: true}
  },
  data () {
    return {
      dragover: false,
      contentImages: [],
      localError: false
    }
  },
  computed: {
    ...mapState({
      previewSize: state => state.config.deposit?.types?.screens?.previewSize,
      rules: state => state.config.deposit?.types?.screens?.validation
    }),
    undeletedFiles () {
      let undeleted = {}
      this.files.forEach((file, i) => {
        if (!file.deleted) undeleted[i] = file
      })
      return undeleted
    }
  },
  watch: {
    content (newVal) {
      this.contentImages = []
      newVal.forEach(this.createImage)
    },
    errorMessages (newVal) {
      this.localError = newVal
    }
  },
  methods: {
    ...mapMutations({
      showNotify: 'notify/show',
      setError: 'error/set',
    }),
    validate (file) {
      if (this.rules.mimes && !this.rules.mimes.includes(file.type)) {
        this.setError({
          mode: 'modal',
          error: {},
          title: this.$t('File type not allowed'),
          message: this.$t(
            `File {{filename}} has type {{type}}, which is not allowed`,
            {filename: file.name, type: file.type}
          ),
        })
        return false
      }
      if (this.rules.max && file.size > this.rules.max) {
        this.setError({
          mode: 'modal',
          error: {},
          title: this.$t('File is too big'),
          message: this.$t(
            `File {{filename}} size is too big — {{size}}, max allowed is {{maxSize}}`,
            {
              filename: file.name,
              size: this.humanize(file.size),
              maxSize: this.humanize(this.rules.max)
            }),
        })
        return false
      }
      return true
    },
    openChooseFile () {
      this.$refs.fileInput.click()
    },
    onDrop (e) {
      this.dragover = false
      let files = Array.from(
        e.dataTransfer ? e.dataTransfer.files : e.target.files
      ).filter(this.validate)
      if (files.length) {
        this.$emit('input', files)
      }
    },
    removeFile (index) {
      this.$emit('remove-file', index)
    },
    removeImage (index) {
      this.localError = false
      this.$emit('remove-content-image', index)
    },
    createImage (file) {
      let reader = new FileReader()
      reader.onload = e => {
        let img = new Image()
        img.onload = () => {
          this.contentImages.push({
            filename: file.name,
            size: file.size,
            width: img.width,
            height: img.height,
            srcBase64: this.resizeImage(img)
          })
        }
        img.src = e.target.result
      }
      reader.readAsDataURL(file)
    },
    resizeImage (img) {
      let canvas = document.createElement('canvas')
      let size = this.previewSize
      let w = img.width
      let h = img.height
      let sw = w
      let sh = h
      let x = 0
      let y = 0
      if (w > h) {
        sw = h
        x = (w - sw) / 2
      } else {
        sh = w
        y = (h - sh) / 2
      }
      canvas.width = size
      canvas.height = size
      canvas.getContext('2d').drawImage(img, x, y, sw, sh, 0, 0, size, size)
      return canvas.toDataURL('image/jpeg')
    }
  }
}
</script>

<style lang="scss" scoped>
  .screens-container {
    position: relative;
    height: 100%;
    min-height: 200px;
  }

  .dropzone {
    cursor: pointer;
    position: absolute;
    top: 0;
    bottom: 0;
    left: -9px;
    right: -8px;
    border: 3px dotted var(--v-secondary-lighten2);

    &.dropzone-error {
      border-color: var(--v-error-base);
    }

    input[type=file] {
      visibility: hidden;
      position: absolute;
      top: 0;
      left: 0;
      height: 0;
      width: 0;
    }
  }

  .dragover {
    border: 3px dashed var(--v-secondary-lighten1);
    background-color: var(--v-background-base);

    .dropzone-label {
      color: var(--v-secondary-lighten1);
    }
  }

  .dropzone-label {
    cursor: pointer;
    color: var(--v-secondary-lighten2);
    position: relative;
    top: 50%;
    left: 50%;
    margin: -2.25rem 0 0 -11rem;
    max-width: 22rem;
    height: 4.5rem;

    &.pushed-to-bottom {
      top: auto;
      margin-top: 2rem;
      margin-bottom: 2rem;
    }
  }
</style>
